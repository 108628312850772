<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="../../assets/img/wang/back.png" @click="back" alt="" />
        <span>详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="info-box" v-loading="ifOpen">
        <div class="head-card">
          <div class="head-card-left">
            <img class="shadow" src="../../assets/img/wang/shadow.png" />
          </div>
          <div class="head-card-right">
            <div class="title-box">
              <!-- <div class="title-box-left"><div>已预警</div></div> -->
              <div class="title-box-right" v-html="myInfo.title ? myInfo.title : ''"></div>
            </div>
            <div class="early-warning">
              <!-- <img class="header-img" src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202004%2F14%2F20200414210224_dnzpo.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672910975&t=4f572bc1a2f09b5a21a599062a8883e0" />
                        <div class="warning-type">涉嫌恐怖</div> -->
              <div class="platform-type" v-if="myInfo.platformName">
                {{ myInfo.platformName }}
              </div>
              <!-- <img class="read" src="../../assets/img/wang/yidu.png" alt="">
                        <div class="text">已读</div> -->
              <div class="time">发布时间：{{ myInfo.publishTime }}</div>
            </div>
            <div class="operation">
              <!-- <div
                class="copy"
                @click="operation({ type: 1, item: myInfo })"
                v-if="!myInfo.collectFlag"
              >
                <img src="../../assets/img/wang/collection.png" />
                <span>加入收藏</span>
              </div> -->
              <!-- <div
                class="copy"
                v-else
                @click="operation({ type: 6, item: myInfo })"
              >
                <img src="../../assets/img/zhang/yishoucang.png" />
                <span>已收藏</span>
              </div> -->
              <div class="copy" @click="$main.copyFun(myInfo.copyText, '复制原文')">
                <img src="../../assets/img/wang/copy-all.png" />
                <span>复制原文</span>
              </div>
              <div class="copy " @click="createImgTask" :class="{ 'disabled': imgUrl }">
                <img src="../../assets/img/wang/jietu.png" />
                <span>截图</span>
              </div>
              <div class="copy" @click="$main.lookYuanWen(myInfo.sourceUrl)">
                <img src="../../assets/img/wang/article.png" />
                <span style="color: #2e59ec">查看原文</span>
              </div>
            </div>
          </div>
          <img class="shadow2" src="../../assets/img/wang/shadow2.png" />
          <!-- <img class="disposed" src="../../assets/img/wang/disposed.png" /> -->
        </div>
        <div class="platform">
          <div class="platform-list">
            <div class="platform-time p-r-30">
              <!-- <span class="textHidden1">
                原文链接：  
                <a
                  class="aColor cursor"
                  @click="$main.lookYuanWen(myInfo.sourceUrl)"
                  >{{ myInfo.sourceUrl }}</a
                >
              </span> -->
              <span style="
                  max-width: 80%;
                  overflow: hidden;
                  word-break: keep-all;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                ">
                原文链接：
                <a :href="myInfo.sourceUrl" target="_blank" style="color: #2878ff" :title="myInfo.sourceUrl">
                  {{ myInfo.sourceUrl }}
                </a>
              </span>
              <img @click="$main.copyFun(myInfo.sourceUrl, '原文链接')" src="../../assets/img/wang/copy-all.png" />
            </div>
            <div class="platform-time">
              <span>发布时间：{{ myInfo.publishTime }}</span><img @click="$main.copyFun(myInfo.publishTime, '复制发布时间')"
                src="../../assets/img/wang/copy-all.png" />
            </div>
          </div>
          <div class="platform-name" v-if="myInfo.platformType == 1">
            网站名称：<span>{{ myInfo.author }}</span><img @click="$main.copyFun(myInfo.author, '复制网站名称')"
              src="../../assets/img/wang/copy-all.png" />
          </div>
          <div class="platform-name" v-else>
            作者：<span>{{ myInfo.author }}</span><img @click="$main.copyFun(myInfo.author, '复制作者')"
              src="../../assets/img/wang/copy-all.png" />
          </div>
          <div class="img" v-loading="loadingImg" element-loading-text="正在截图中">
            <el-image v-if="imgUrl" :src="imgUrl" class="img-c" :preview-src-list="[imgUrl]">
            </el-image>
            <img v-else class="img-c" src="../../assets/img/gjh/head-r.png" alt="" srcset="" />
          </div>
        </div>
        <div class="content content-desc" v-html="myInfo.content ? myInfo.content : ''"></div>
      </div>
      <!-- 相似信息 -->
      <!-- <div class="be-similar">
            <div class="be-similar-account"><span>相似信息</span><span>（12条）</span> <div class="be-similar-color"></div></div>
            <div class="data-box">
                <div class="data-box-right">
                <div class="name-box">
                     <div class="name-box-left">
                        <span>涉嫌赌博</span>
                        <span>涉嫌恐怖</span>
                        <div class="name">阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团阿里巴巴集团</div>
                     </div>
                    <div class="time">2021年03月21日 12:34</div>
                </div>
                <div class="data-info">
                    <div class="subject-type">平台类型：微博</div>
                    <div class="region">所属区域：浙江省-杭州市-莲湖区</div>
                    <div class="subject-type">账号名称：企业</div>
                    <div class="company">作者名称：名称名称名称</div>
                </div>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.vue";
import { detail } from "../../api/publishContent/index";
import { update } from "../../api/publishContent/index";
import { createImgTask } from "../../api/createImgTask/index";
export default {
  components: { Breadcrumb },
  data() {
    return {
      routerList: ["发布内容", "详情"],
      myInfo: {}, // 详情信息
      id: null,
      ifOpen: true, // 加载中
      imgUrl: "", // 截图
      loadingImg: false,
      infos: {}
    };
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.id = params.id;
      this.platformTypeName = params.platformTypeName;
      this.infos = params
    }
    this.getdetail();
  },
  mounted() { },
  methods: {
    async getdetail() {
      const { id } = this;
      const { publishTime } = this.infos
      console.log(publishTime)
      const Res = await detail({
        dataId: id,
        ...this.$main.snapshot(publishTime)
      });
      // if(this.$main.ifRes(Res)) return
      const { data } = this.$main.getResData(Res);
      this.myInfo = data;
      this.imgUrl = data?.imagePath;
      this.ifOpen = false;
    },
    back() {
      this.$router.push("/publishingcontent");
    },
    // 更新  已-未读 删除 收藏
    async operation({ type, item }) {
      // 操作类别-type: 1 收藏 2 删除 3 已读 4 未读 5 撤销删除 6 取消收藏
      const { id } = item;
      const Res = await update({
        dataIds: id,
        operationType: type,
        ...this.$main.snapshot(item.publishTime)
      });
      const { code } = Res.data;
      if (code == 200) {
        let typeText = "";
        if (type == 1) {
          typeText = "已收藏";
          item.collectFlag = true;
        } else if (type == 6) {
          typeText = "取消收藏";
          item.collectFlag = false;
        }
        this.$message({
          message: `${typeText ? typeText : "操作成功"}`,
          type: "success",
        });
      } else {
        this.$message.error("操作失败!");
      }
    },
    //截图
    async createImgTask() {
      this.loadingImg = true;
      try {
        let data = {
          dataId: this.id,
          type: 2, //平台台账1，发布内容2，有害信息3，问题平台4，专项行动5，错误表述6，监测方案7
          url: this.myInfo.sourceUrl, //信息网址
          userId: JSON.parse(window.localStorage.getItem("user")).userId,
        };
        console.log(data)
        this.initWebSocket();
        this.$message.warning("正在截图,请稍等");
        // this.websock.onmessage
        await this.$main.mySetTimeOut(2000);
        let res = await createImgTask(data);
        if (res.data.code !== 200) {
          this.$message.error("截图失败1");
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loadingImg = false;
      }

    },
    //-----------------------------------------------------------
    //初始化weosocket
    initWebSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持WebSocket");
        return false;
      }
      const wsurl =
        "wss://api.sd.wangxinban.cn/websocket/" +
        window.localStorage.getItem("token"); // 正式地址
      // const wsurl = "ws://172.16.76.242:8301/websocket/"+JSON.parse(sessionStorage.getItem('userInfo')).userId   // 测试地址
      this.websock = new WebSocket(wsurl);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    //连接成功
    websocketonopen() {
      console.log("WebSocket连接成功");
      // let action = { message: "1" };
      // this.websocketsend(JSON.stringify(action));
    },
    //接收后端返回的数据
    websocketonmessage(e) {
      console.log("WebSocket返回数据");
      let dataJson = JSON.parse(e.data);
      this.imgUrl = dataJson.imagePath;
      this.$message.success("截图成功");
      this.loadingImg = false;
      // 在这里使用后端返回的数据，对数据进行处理渲染
    },
    //连接建立失败重连
    websocketonerror(e) {
      console.log(`连接失败的信息：`, e);
      this.initWebSocket(); // 连接失败后尝试重新连接
    },
    //关闭连接
    websocketclose(e) {
      console.log("断开连接", e);
    },
    websocketsend(Data) {
      //数据发送
      // console.log("数据发送", Data);
      this.websock.send(Data);
    },
  },
};
</script>

<style lang="scss" scoped>
.overall-page {
  width: 100%;

  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;

    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;

      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }

      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }

  .page {
    padding-top: 72px;

    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;

      .head-card {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #e4e6ec;

        .head-card-left {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: auto;
          width: 200px;

          .shadow {
            height: 74px;
            width: 57px;
          }
        }

        .head-card-right {
          display: flex;
          flex-direction: column;
          width: calc(100% - 370px);
          margin: 40px 0px 32px 0px;
        }

        .title-box {
          display: flex;
          width: 100%;
          min-height: 42px;

          .title-box-left {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: 58px;
            margin-right: 16px;

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 58px;
              height: 24px;
              background: #ffedef;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
              white-space: nowrap;
              margin-top: 10px;
            }
          }

          .title-box-right {
            width: calc(100% - 60px);
            min-height: 42px;
            line-height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }

        .early-warning {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          margin: 16px 0px;

          .header-img {
            height: 50px;
            width: 52px;
            border-radius: 50%;
            margin-right: 24px;
          }

          .warning-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #ff4b1f;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }

          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #f58030;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }

          .read {
            width: 17px;
            height: 15px;
            margin-right: 8px;
          }

          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            margin-right: 16px;
          }

          .time {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }

        .operation {
          display: flex;
          height: 20px;
          width: auto;

          .copy {
            display: flex;
            align-items: center;
            height: 20px;
            width: auto;
            cursor: pointer;
            margin-right: 41px;

            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }

            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }

        .shadow2 {
          height: 100%;
          width: 170px;
        }

        .disposed {
          height: 114px;
          width: 129px;
          position: absolute;
          margin-left: calc(100% - 386px);
        }
      }

      .platform {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 1164px;
        height: auto;
        background: #f4f5fa;
        padding: 24px 24px 0px 32px;
        margin: 24px 0px 24px 0px;

        .img {
          height: 110px;
          width: 110px;
          position: absolute;
          margin-left: 1000px;
          margin-bottom: 24px;

          .img-c {
            width: 100%;
            height: 100%;
          }
        }

        .platform-list {
          display: flex;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;

          .platform-time {
            display: flex;
            // align-items: center;
            height: 20px;
            width: 40%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            img {
              height: 16px;
              width: 17px;
              margin-left: 10px;
              margin-top: 3px;
              cursor: pointer;
            }
          }
        }

        .platform-name {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;

          img {
            height: 16px;
            width: 17px;
            margin-left: 10px;
            margin-top: 3px;
            cursor: pointer;
          }
        }
      }

      .content {
        width: 1164px;
        height: auto;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 40px 240px 26px 240px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;

      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;

        span:nth-child(1) {
          z-index: 10;
        }

        span:nth-child(2) {
          font-size: 14px;
        }

        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }

      .data-box {
        display: flex;
        width: 100%;
        height: auto;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;

        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }

          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }

        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;

          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-bottom: 16px;

            .name-box-left {
              display: flex;
              align-items: center;
              width: calc(100% - 170px);
              height: auto;

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 24px;
                padding: 0px 8px;
                background: #ff4b1f;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                margin-right: 16px;
              }

              .name {
                height: 22px;
                width: calc(100% - 200px);
                line-height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            .time {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 100%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }

            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }

          .data-info {
            display: flex;
            height: 20px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            .status {
              color: #02bc7c;
              margin-right: 46px;
            }

            .region {
              max-width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }

            .subject-type {
              margin-right: 46px;
            }

            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }

            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }
  }
}
</style>